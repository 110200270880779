import { genderLabel } from "@config"

export const MIN_INVESTMENT_AMOUNT = 10
export const MAX_INVESTMENT_AMOUNT = 300
export const STEP_INVESTMENT_AMOUNT = 10
export const DEFAULT_INVESTMENT_AMOUNT = 50

export const PUBLIC_MIN_AVERAGE_MARKET_RETURN = 2
export const PUBLIC_MAX_AVERAGE_MARKET_RETURN = 12
export const PUBLIC_MAX_AVERAGE_MARKET_RETURN_GENDERLESS = 20
export const PUBLIC_STEP_AVERAGE_MARKET_RETURN = 1
export const PUBLIC_DEFAULT_AVERAGE_MARKET_RETURN = 8
export const PUBLIC_DEFAULT_CLIENT_AGE = 55
export const PUBLIC_DEFAULT_GENDER = genderLabel.male

export const PUBLIC_MIN_INVESTMENT_AMOUNT = 10000
export const PUBLIC_MAX_INVESTMENT_AMOUNT = 300000
export const PUBLIC_STEP_INVESTMENT_AMOUNT = 10000
export const PUBLIC_DEFAULT_INVESTMENT_AMOUNT = 50000
export const PUBLIC_DEFAULT_MALE_WITHDRAWAL_AGE = 77
export const PUBLIC_DEFAULT_FEMALE_WITHDRAWAL_AGE = 82
export const PUBLIC_DEFAULT_GENDERLESS_WITHDRAWAL_AGE = 82
